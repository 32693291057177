<template>
  <div class="test_group_warp">
    <div class="left">
      <LeftController @initList="initList"
                      ref="subjectRef" />
    </div>
    <div class="right">
      <TopCard ref="TopCardRef"
               text="我的试卷"
               :isTabs="true"
               :tabList="tabList"
               :showBack="false" />
      <div class="list">
        <!-- 我的试卷 未批改试卷 已批改试卷 列表 -->
        <div class="warp"
             v-if="idx < 3">
            <div v-for="(row,index) in tableData"
                  :key="index"
                  class="paper_list">
                <div class="paper_img">
                  <div style="font-size: 20rem;font-weight:400;line-height:30rem;color: #999;">
                    <!-- 总分：{{row.paper_score}} -->
                    {{row.paper_time}}
                  </div>
                  <div class="img"><img src="@/assets/choiceness/mypaper.png" alt=""></div>
                </div>
                <div class="paper_title">
                  <div style="display: flex;gap: 16rem;">
                    <span v-if="row.template_name != null && row.template_name.length != 0" style="font-size: 24rem;font-weight: 600;line-height: 36rem;color: #2196f3;border-radius: 8rem;border: 1rem solid #2196f3;padding: 4rem 8rem;">
                      {{row.template_name}}
                    </span>
                    <span style="font-size: 24rem;font-weight: 600;line-height: 36rem;color: #666;border-radius: 8rem;border: 1rem solid #666;padding: 4rem 8rem;">
                      {{row.correct_type == 1 ? '教师批改试卷' : '自评试卷'}}
                    </span>
                  </div>
                  <div>
                    <span class="overflow-hidden" style="font-size: 28rem;font-weight: 600;line-height: 42rem;">{{row.paper_desc?row.paper_desc:'无'}}</span>
                  </div>
                  <div style="font-size: 24rem;font-weight: 400;line-height: 36rem;color: #999;">
                    <span>试卷ID:</span>
                    <span>{{row.user_paper_id}}</span>
                  </div>
                  <!-- <div class="comment">
                    <span>
                      试卷总评:</span>
                    <span>
                      {{row.paper_comment?row.paper_comment:'无'}}
                    </span>
                  </div> -->
                </div>
              <div class="paper_score">
                <div v-if="correctFinishedCanShow(row)" style="display: flex;gap: 32rem;">
                  <!-- <div><span>排名：</span><span style="color:#FB9930">{{row.rank}}</span></div> -->
                  <div v-if="parseFloat(row.user_score) != 0"><span style="color: #666;">打败了{{row.percent}}%人</span></div>
                </div>
                <div><span>我的得分：</span><span style="color:#F2413A;font-weight:600;">{{row.user_score}}</span></div>
                <div><span>总分：</span><span style="color:#333;font-weight:600;">{{row.paper_score}}</span></div>
                <div v-if="correctFinishedCanShow(row)"><span>平均得分：</span><span style="color:#333;font-weight:600;">{{row.avg_score}}</span></div>
                <div style="display: flex;flex-wrap: wrap;align-self: stretch;">
                  <span style="color: #999;">总评：</span>
                  <span class="overflow-hidden" style="flex: 1 0 0;color:#333">
                    <label v-if="row.paper_comment && row.paper_comment.length > 18" @click="showCommentDetail(row)" style="color: #2196f3; float: right;clear: both;line-height: 36rem;cursor: pointer;">查看全部</label>
                    {{row.paper_comment?row.paper_comment:'无'}}
                  </span>
                </div>
              </div>
              <!-- <div :class="{'desc':true,'check':row.correct_type == 2 && selfCorrectStatus.indexOf(row.judge_status) != -1}">
                {{getValue(row)}}
              </div> -->
              <div :class="correctFinished.indexOf(row.judge_status) != -1 ? 'correct-info' : 'correct-danger'">
                <span>{{getValue(row)}}</span>
              </div>
              <div>
                <div class="paper_btn"
                     style="background: #f2413a;"
                     @click="toCorrect(row)"
                     v-if="row.correct_type == 2 && selfCorrectStatus.indexOf(row.judge_status) != -1">
                  <!-- 自评状态  且 未批改 -->
                  批改试卷
                </div>
                <div class="paper_btn"
                     style="background: #2196f3;"
                     @click="toAnalysis(row)"
                     v-else>
                  答案解析
                </div>
              </div>
            </div>
        </div>

        <!-- 未完成试卷列表 -->
        <div class="warp"
             v-else>
          <el-row v-for="(row,index) in tableData"
                  :key="index">
            <el-col :span="18">
              <div class="paper_name">
                <div class="img">
                  <img src="@/assets/choiceness/underpaper.png"
                       alt="">
                </div>
                <div class="text">
                  <div>
                    {{row.template_name}}
                  </div>
                  <div>
                    <span>
                      试卷ID:</span>
                    <span>
                      {{row.user_paper_id}}
                    </span>
                  </div>
                  <div>
                    做卷时间：{{row.paper_time}}
                  </div>
                  <div>
                    试卷描述：{{row.paper_desc}}
                  </div>
                </div>
              </div>
            </el-col>

            <el-col :span="3">
              <div class="score">
                <span style="font-weight:bold;">用时：</span><span style="color:#FF634C">0分</span>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="btn_warp">
                <div class="btn"
                     @click="doPaper(row)">
                  继续做卷
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="commentVisiable"
      :close-on-click-modal="false"
      :append-to-body="true"
      title="总评"
      :show-close="false"
      :center="true"
      width="720rem">
      <span>{{ commentDetail?commentDetail:'无' }}</span>
      <el-button @click="() => {commentVisiable=false;commentDetail='';}" slot="footer" type="text">关闭</el-button>
    </el-dialog>
  </div>
</template>

<script>
import LeftController from '@/views/components/left_controller.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getMyPaper } from '@/api/my_paper.js'
export default {
  data () {
    return {
      tableData: [],
      paperType: 1,
      id: 0,
      idx: 0,
      tabList: [
        { label: '我的试卷', value: -1 },
        { label: '未批改试卷', value: 0 },
        { label: '已批改试卷', value: 1 },
        { label: '未完成试卷', value: 2 },
      ],
      limit: 20,
      page: 1,
      selfCorrectStatus: [101, 1, 102, 2, 103, 3, 10, 7, 107],
      correctFinished: [4,104,105,106],
      commentVisiable: false,
      commentDetail: '',
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == 'index') {
      from.meta.keep = false;
    }
    next();
  },
  components: {
    LeftController, TopCard
  },
  mounted () {
    this.$removePaperInfo()
    document.getElementsByClassName('list')[0].addEventListener('scroll', this.addScrollEvent)
  },
  activated () {
    if (!this.$refs.subjectRef.subjectList.length) {
      this.$refs.subjectRef.initData()
    }
  },
  methods: {
    addScrollEvent () {
      var olist = document.getElementsByClassName('list')[0]
      // 容器高度 + 滚动条距离顶边的距离 = 滚动条的高度
      if (olist.clientHeight + olist.scrollTop == olist.scrollHeight) {
        this.page += 1
        this.initList(this.id)
      }
    },
    checkStatus (index) {
      this.idx = index
      this.page = 1
      this.initList(this.id)
      this.tableData = []
    },
    async initList (id) {
      if (id != this.id) {
        this.id = id
        this.page = 1;
        this.tableData = []
      }
      let params = {
        subject_id: id,
        status: this.tabList[this.idx].value,
        page: this.page,
        limit: 20
      }
      const { data } = await getMyPaper(params)
      this.tableData = [...this.tableData, ...data.volist]
    },
    toCorrect (row) {
      this.$router.push('/self_correct?paper_id=' + row.user_paper_id)
    },
    toAnalysis (item) {
      this.$router.push('/analysis?paper_id=' + item.user_paper_id)
    },
    doPaper (row) {
      this.$router.push('/doPaper?type=2&form=' + JSON.stringify({ user_paper_id: row.user_paper_id }))
    },
    getValue (row) {
      var valus = {
        101: '选择题系统自动批改，非选择题未批改',
        1: '选择题系统自动批改，非选择题未批改',
        102: '系统自动批改，大题学生部分批改',
        103: '学生部分批改（全大题，批改部分）',
        104: '学生完成批改（全大题，全部批改完成）',
        4: '全部批改完成（选择题+ 大题）',
        105: '系统自动批改完成（全选择题）',
        106: '全部批改完成（选择题+ 大题）',
        107: '大题全部未批改',
        16: '试卷批改中'

      }
      return valus[row.judge_status]
    },
    correctFinishedCanShow (row) {
      return (this.correctFinished.indexOf(row.judge_status) != -1 && row.famous_paper_source != 0)
    },
    showCommentDetail (row) {
      console.log(row)
      this.commentDetail = row.paper_comment
      this.commentVisiable = true
    },
  }
}
</script>

<style lang="scss" scoped>
.overflow-hidden {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
.overflow-hidden::before {
  float: right;
  content: '';
  background-color: red;
  /* 减去尾随按钮高度  */
  height: calc(100% - 36rem);
}
.test_group_warp {
  overflow: hidden;
  display: flex;
  .left {
    width: 100rem;
  }
  .right {
    width: calc(100% - 100rem);
    background: #f6f6f6;
    .tabs {
      margin-top: 22rem;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18rem;
      .tab {
        cursor: pointer;
        width: 260rem;
        height: 54rem;
        background: #2196f3;
        border-radius: 30rem;
        color: #ffffff;
        font-size: 24rem;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .list {
      width: 100%;
      max-height: calc(100vh - 60rem - 40rem);
      -ms-overflow-style: none;
      background: white;
      overflow-y: auto;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .el-row {
        padding: 10rem;
        border-bottom: 1rem solid #f1f1f1;
        display: flex;
        align-items: center;
        &:hover {
          background: rgba($color: #2196f3, $alpha: 0.1);
        }
      }
      // div {
      //   height: 80rem;
      //   line-height: 80rem;
      // }
      .paper_name {
        margin-left: 50rem;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        // height: 110rem;
        padding: 10rem;
        .text {
          min-height: 70rem;
          margin-left: 20rem;
          div {
            width: 700rem;
            &:nth-child(1) {
              font-size: 20rem;
              font-weight: bold;
              color: #000000;
            }
            &:nth-child(2) {
              margin-top: 10rem;
              font-size: 20rem;
              font-weight: bold;
              color: #000000;
              // line-height: 20rem;
            }
            &:nth-child(3) {
              margin-top: 10rem;
              font-weight: 500;
              font-size: 20rem;
              color: #333333;
              display: flex;
              flex-wrap: wrap;
              word-wrap: break-word;
              span {
                &:nth-child(1) {
                  width: 100rem;
                }
                &:nth-child(2) {
                  width: calc(100% - 200rem);
                }
              }
            }
            &:nth-child(4) {
              margin-top: 10rem;
              font-weight: 500;
              font-size: 20rem;
              color: #333333;
              display: flex;
              flex-wrap: wrap;
              word-wrap: break-word;
              span {
                &:nth-child(1) {
                  width: 100rem;
                }
                &:nth-child(2) {
                  width: calc(100% - 200rem);
                }
              }
            }
          }
        }
      }
      .score {
        font-weight: 500;
        color: #333333;
        font-size: 20rem;
        // height: 110rem;
        display: flex;
        align-items: center;
        margin-left: 30rem;
      }
      .btn_warp {
        display: flex;
        // height: 110rem;
        align-items: center;
        justify-content: flex-end;
        .btn {
          margin-right: 20rem;
          width: 120rem;
          height: 44rem;
          background: #2196f3;
          border-radius: 5rem;
          font-size: 20rem;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
.desc_col {
  display: flex;
  align-items: center;
  // height: 110rem;
  justify-content: flex-end;
  .desc {
    padding: 5rem 30rem;
    background: #ececec;
    border-radius: 16rem;
    font-weight: 500;
    font-size: 16rem;
  }
}
.check {
  background: #ffecea !important;

  color: #ff6552 !important;
}

.paper_list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 24rem 40rem;
  max-height: 296rem;
  border-bottom: 1rem solid #eee;
  gap: 40rem;
  .paper_img {
    display: flex;
    gap: 8rem;
    flex-direction: column;
    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 240rem;
      height: 160rem;
      background: #6cb9f5;
      border-radius: 16rem;
      flex-shrink: 0;
      img {
        width: 48rem;
        height: 48rem;
      }
    }
  }
  .paper_title {
    width: 468rem;
    display: flex;
    gap: 8rem;
    flex-direction: column;
  }
  .paper_score {
    width: 400rem;
    display: flex;
    gap: 8rem;
    flex-direction: column;
    font-size: 24rem;
    font-weight: 400;
    line-height: 36rem;
    color: #999;
  }
  .correct-danger {
    width: 320rem;
    border-radius: 16rem;
    padding: 8rem 16rem;
    background-color: rgba(244, 65, 58, 0.1);
    span {
      font-size: 28rem;
      font-weight: 400;
      line-height: 42rem;
      color: #F2413A;
    }
  }
  .correct-info {
    width: 320rem;
    border-radius: 16rem;
    padding: 8rem 16rem;
    background-color: rgba(51, 51, 51, 0.1);
    span {
      font-size: 28rem;
      font-weight: 400;
      line-height: 42rem;
      color: #333;
    }
  }
  .paper_btn {
    cursor: pointer;
    width: 96rem;
    border-radius: 8rem;
    padding: 8rem 28rem;
    font-size: 24rem;
    font-weight: 400;
    line-height: 36rem;
    align-items: center;
    color: #fff;
  }
}
</style>